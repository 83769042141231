<!--
 * @Description: AKJERRT
 * @Date: 2022-08-12 11:42:23
 * @LastEditTime: 2022-08-15 14:45:36
 * @FilePath: \linkgap-front\src\views\system\versionIteration.vue
-->
<template>
  <div>
    <a-card :loading="loading">
      <a-divider orientation="left"> 版本迭代 </a-divider>
      <a-timeline>
        <a-timeline-item v-for="item in data" :key="item.id">
          <p><span style="font-size: 1.5rem;">{{ item.createTime }}</span>&emsp;{{ item.upVersion }}</p>
          <p style="font-size: 1.5rem;">{{ item.title }}</p>
          <div class="up-version-box">
            <span class="up-version">更新用户：</span><span>{{ item.createUser }}</span>
          </div>
          <div>
            <span class="up-version">更新内容：</span>
            <span v-html="item.content" class="content"></span>
          </div>
        </a-timeline-item>
      </a-timeline>
    </a-card>
  </div>
</template>

<script>
import { getUpgradeRecordList, delUser } from '@/api/system'

export default {
  name: 'VersionIteration',
  data() {
    return {
      // 时间轴数据
      data: [],
      loading: false,
      // 查询参数
      queryParam: {
        current: 1,
        pageSize: 1000,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList(num) {
      if (num == 1) {
        this.queryParam = {}
      }
      let params = Object.assign(this.queryParam)
      getUpgradeRecordList(params).then(({ code, body, message }) => {
        if (code === 200) {
          this.data = body.records
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.up-version-box {
  display: flex;
}
.up-version {
  width: 5rem;
  display: block;
  text-align: right;
}
.content {
  margin-left: 3rem;
  display: block;
}
</style>